<template>
  <div class="main-content">
    <div class="profile-menu-list profile-menu">
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingCreateGroup' }"
      >
        開團設定
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingCreateGroupRemark' }"
      >
        團主說明預設
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingTransfer' }"
      >
        匯款資料
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingCanceledNotification' }"
      >
        流團通知
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingValidNotification' }"
      >
        成團通知
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingActiveNotification' }"
      >
        團購中通知
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingReducedNotification' }"
      >
        <!-- 原刪單通知 -->
        增減刪單通知
      </router-link>
      <router-link
        class="list-item"
        :to="{ name: 'MemberDefaultSettingValidGroupNotification' }"
      >
        付款領貨通知
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
